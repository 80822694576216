import postsHttp from '@/http/posts'
import useTableHelper from '@/helpers/useTableHelper'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

const {getPosts, changePostBlockStatus, deletePost, changePostFavouriteStatus, changePostFavouritePlusStatus} = postsHttp

import { useToast } from 'vue-toastification/composition'
import { ref, watch, computed } from '@vue/composition-api'
import moment from 'moment'

export default function usePosts(isSponsored, isReported) {
  const toast = useToast()
  const {
    refTable,

    perPage,
    totalCount,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    dataMeta,
    refetchData
  } = useTableHelper()

  const industryFilter = ref(null)
  const userRole = ref(null)
  const isFavourite = ref(false)
  const isBlocked = ref(false)
  const createdBy = ref(null)
  const created = ref(null)
  const sponsoredDate = ref(null)
  const position = ref(null)
  const viewsRange = ref(null)

  const tableColumns = [
    {key: 'user'},
    {key: 'createdAt', label: 'Created'},
    {key: 'content'},
    {key: 'media', label: 'Media'},
    {key: 'views', label: 'Views'},
    {key: 'isBlocked', label: 'Is Blocked'},
    {key: 'favourite', label: 'Favourite'},
    {key: 'isSponsored', label: 'Promoted'},
    {key: 'actions', label: 'Actions'},
  ]

  if(isReported){
    tableColumns.splice(1, 0,
        {
          key: 'reportsPerPost',
          label: 'Num of Reports'
        });
  }

  const sponsoredColumns = [
    {key: 'user'},
    {key: 'position'},
    {key: 'validFrom', label: 'From'},
    {key: 'validTo', label: 'To'},
    {key: 'content'},
    {key: 'views', label: 'Views'},
    {key: 'media', label: 'Media'},
    {key: 'isSponsored', label: 'Promoted'},
    {key: 'actions', label: 'Actions'},
  ]

  const advancedFilters = computed(() => {
    const [fromDate, untilDate] = created.value !== null  ? created.value.split(' to ') : []
    const [viewsFromDate, viewsToDate] = viewsRange.value !== null  ? viewsRange.value.split(' to ') : []
    const [sponsoredFromDate, sponsoredUntilDate] = sponsoredDate.value !== null  ? sponsoredDate.value.split(' to ') : []

    return {
      userType: userRole.value,
      fieldOfWorks: industryFilter.value,
      fromDate,
      untilDate,
      viewsFromDate,
      viewsToDate,
      position: position.value,
      sponsoredFromDate,
      sponsoredUntilDate,
      isFavourite: isFavourite.value === true ? true : null,
      isBlocked: isBlocked.value === true ? true : null,
      isReported: isReported ? true : null
    }
  })

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch([userRole, industryFilter, created, createdBy, isBlocked, isFavourite, position, sponsoredDate, viewsRange], () => {
    currentPage.value = 1;
    refetchData();
  })

  const fetchPosts = (ctx, callback) => {
    getPosts(isSponsored, currentPage.value, perPage.value, createdBy.value, isReported, advancedFilters.value)
      .then(response => {
        callback(response.results)
        totalCount.value = response.totalCount
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching jobs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const removePost = async (uid) => {
    try {
      await deletePost(uid);
      refetchData()
    } catch (e) {}
  }

  const changeBlockStatus = async (post) => {
    try {
      await changePostBlockStatus(post.uid)
      post.isBlocked = !post.isBlocked
    } catch (e) {
      console.log(e)
    }
  }

  const changeFavouriteStatus = async (post) => {
    try {
      await changePostFavouriteStatus(post.uid)
      post.isFavourite = !post.isFavourite
    } catch (_) {}
  }

  const changeFavouritePlusStatus = async (post) => {
    try {
      await changePostFavouritePlusStatus(post.uid)
      post.isFavouritePlus = !post.isFavouritePlus
    } catch (_) {}
  }

  const shortContent = value => {
    const dots = value?.length > 50 ? '...' : ''
    return value ? value.slice(0, 50) + dots  : ''
  }

  const resolvePostLink = (user, postId) => {
    const baseUrl = process.env['VUE_APP_REDIRECT_LINK']
    return baseUrl + `/${user.role}s/${user.uid}?postId=${postId}`
  }

  const resolveCreated = (dateTime, removeHours = false) => {
    let date = new Date(dateTime)
    const format = removeHours ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm'
    return moment(date).format(format)
  }



  return {
    refTable,
    tableColumns,
    sponsoredColumns,

    perPage,
    totalCount,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    position,
    sponsoredDate,

    dataMeta,
    refetchData,

    fetchPosts,
    shortContent,
    resolvePostLink,
    removePost,
    changeBlockStatus,
    changeFavouriteStatus,
    resolveCreated,

    industryFilter,
    userRole,
    changeFavouritePlusStatus,
    isFavourite,
    isBlocked,
    createdBy,
    created,
    viewsRange
  }

}
