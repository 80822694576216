<template>
  <div>
    <h3 class="mb-2">{{ isEdit ? 'Edit' : 'Add' }} sponsored post</h3>
    <b-form-group
        label="Position"
        label-for="position"
    >
      <v-select
          v-model="position"
          id="position"
          :options="positions">
      </v-select>
    </b-form-group>

    <div>
      <label>Date</label>
      <flat-pickr
          v-model="date"
          class="form-control"
          :config="{ mode: 'range'}"
      />
    </div>

    <div class="d-flex justify-content-end mt-2 mb-1">
      <b-button
          variant="secondary"
          class="mr-1"
          @click="$emit('close')"
      >
        Cancel
      </b-button>
      <b-button @click="save" variant="primary">Save</b-button>
    </div>
  </div>
</template>

<script>
import {BFormInput, BFormGroup, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { toastMixin } from '@/mixins/toast-mixin'

export default {
  name: 'EditSponsored',
  props: {
    isEdit: Boolean,
    uid: String,
    post: Object
  },
  mixins: [toastMixin],
  components: {
    BFormInput,
    BFormGroup,
    vSelect,
    flatPickr,
    BButton
  },
  data() {
    return {
      position: 2,
      positions: [],
      date: null
    }
  },
  methods: {
    async save() {
      try {
        const [fromDate, untilDate] = this.date !== null  ? this.date.split(' to ') : []
        await this.$httpService.posts.addToSponsored(this.uid, this.position, fromDate, untilDate, this.isEdit ? 'put' : 'post')
        this.$emit('sponsorCreated', this.uid)
      } catch (e) {
        this.showToast('error', 'AlertTriangleIcon', 'Error', 'Something went wrong!')
      }
    }
  },
  created() {
    this.$httpService.posts.getSponsoredPositions().then(res => {
      this.positions = res
    })
    if(this.post) {
      this.position = this.post.position
      const dateFrom = this.post.validFrom?.split('T')[0]
      const dateTo = this.post.validTo?.split('T')[0]
      if(dateFrom && dateTo) {
        this.date = `${dateFrom} to ${dateTo}`
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.checkbox {
  cursor: pointer;
}
</style>
