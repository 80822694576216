
<template>
  <section class="vueVideo">
    <video-player
      :options="playerOptions"
      class="video-player-box"
      :playsinline="playsinline"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
      @loadeddata="onPlayerLoadeddata($event)"
      @waiting="onPlayerWaiting($event)"
      @playing="onPlayerPlaying($event)"
      @timeupdate="onPlayerTimeupdate($event)"
      @canplay="onPlayerCanplay($event)"
      @canplaythrough="onPlayerCanplaythrough($event)"
      @ready="playerReadied"
      @statechanged="playerStateChanged($event)"
    ></video-player>
  </section>
</template>
<script>

import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'

export default {
  props: {
    src: String
  },
  components: {
    videoPlayer
  },
  data() {
    return {
      playsinline: true,
      playerOptions: {
        // Player configuration
        height: '400px',
        muted: false, // Whether to mute
        // aspectRatio: '16:9',
        playbackRates: [0.7, 1.0, 1.5, 2.0], // playback speed
        controls: true,
        preload:'auto', // video preload
        // fluid: true,
        sources: [
          {
            type: 'video/mp4',
            src:
              this.src
          }
        ],
        notSupportedMessage:'This video is temporarily unavailable, please try again later'
      },
      playing: false,
      paused: false
    }
  },
  mounted() {

  },
  methods: {
    visibilityChanged(isVisible) {
      if(this.playing && !isVisible) {
        this.myVideoPlayer.pause();
      }
      if(isVisible && this.paused) {
        this.myVideoPlayer.play();
      }
    },
    // monitor playback
    onPlayerPlay(player) {
      this.playing = true;
      this.paused = false;
    },
    // monitor pause
    onPlayerPause(player) {
      this.playing = false;
      this.paused = true;
    },
    // stop listening
    onPlayerEnded(player) {
      // console.log('player ended!', player)
    },
    // monitor loading is complete
    onPlayerLoadeddata(player) {
      // console.log('player Loadeddata!', player)
    },
    // monitor video buffer waiting
    onPlayerWaiting(player) {
      // console.log('player Waiting!', player)
    },
    // Watch the video play after pause
    onPlayerPlaying(player) {
      // this.playing = true;
    },
    // Monitor video playback duration update
    onPlayerTimeupdate(player) {
      // console.log('player Timeupdate!', player.currentTime())
    },
    onPlayerCanplay(player) {

    },
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },
    // monitor state changes
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    // The monitor player is ready
    playerReadied(player) {
      // console.log('example 01: the player is readied', player)
    }
  }
}
</script>
<style lang="scss">
.video-player-box {
  display: flex;
  justify-content: center;
  background: black;
  position: relative;

  & > div {
    position: unset;
  }

  .vjs-big-play-button {
    border-radius: 0 !important;
  }
}
.vjs-big-play-button {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
</style>
