<template>
  <div class="post-text">
    <span v-if="text" v-html="postText"></span>
    <span class="read-more-btn" role="button" v-if="cut < 10000 && text.length > 400" @click="readMore">read more</span>
<!--    <span class="ml-2">...read more</span>-->
  </div>
</template>

<script>
export default {
  props: ['text'],
  data() {
    return {
      cut: 400
    }
  },
  computed: {
    postText() {
      const hashReg = new RegExp('#([^\\s]*)','g');
      const dots = this.text.length > this.cut ? '...' : ''

      return this.text.slice(0, this.cut).replace(hashReg, `<span class="hashtag">#$1</span>`) + dots;
    }
  },
  methods: {
    readMore() {
      this.cut = 10000;
    }
  }
}
</script>

<style lang="scss" scoped>
.post-text {
  font-size: 14px;
  color: var(--color-gray);
  line-height: 1.7;
  white-space: break-spaces;

  .read-more-btn {
    color: var(--color-primary);
    margin-left: 10px;
  }

  @media (max-height: 600px){
    font-size: 12px;
  }
}
</style>

<style>
.hashtag {
  color: var(--color-primary);
}
</style>
