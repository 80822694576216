<template>
  <div class="og" @click="redirectToPage">
    <div class="og__image" v-if="ogContent.ogImage && image">
      <img :src="image" alt="">
    </div>
    <div class="og__text">
      <div class="og__url">
        {{url}}
      </div>
      <div class="og__title">
        {{ogContent.ogTitle}}
      </div>
    </div>
  </div>
</template>

<script>
const psl = require('psl');

export default {
  name: 'PostLinkPreview',
  props: ['ogContent'],
  computed: {
    url() {
      try {
        return psl.get(this.extractHostname(this.ogContent.requestUrl));
      } catch (e) {
        return ''
      }
    },
    image() {
     try {
       if(this.ogContent?.ogImage?.url && this.ogContent.ogUrl && this.ogContent?.ogImage?.url?.charAt(0) === '/') {
         return this.ogContent.ogUrl + this.ogContent?.ogImage?.url
       }
       if(this.ogContent?.ogImage?.url && this.ogContent?.ogImage?.url?.charAt(0) !== '/') {
         return this.ogContent?.ogImage?.url
       }
       return null
     } catch (e) {
       return null
     }
    }
  },
  methods: {
    redirectToPage() {
      window.open(this.ogContent.requestUrl, '_blank');
    },
    extractHostname(url) {
      let hostname;
      //find & remove protocol (http, ftp, etc.) and get hostname

      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      }
      else {
        hostname = url.split('/')[0];
      }

      //find & remove port number
      hostname = hostname.split(':')[0];
      //find & remove "?"
      hostname = hostname.split('?')[0];

      return hostname;
    }
  }
}
</script>

<style lang="scss" scoped>
.og {
  cursor: pointer;
  &__image {
    height: 350px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 600px) {
      height: 195px;
    }
  }

  &__text {
    background: var(--color-gray-background);
    padding: 10px;
  }

  &__url {
    font-size: 12px;
    color: var(--color-gray);
  }

  &__title {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
