<template>
  <div class="post" :class="{'post--shared': shared}">

    <post-text v-if="post.content" :text="post.content"/>

    <post-images :images="post.images" v-if="post.images.length > 0" class="mt-3"></post-images>
    <post-link-preview v-if="ogContent && post.images.length === 0 && post.videos.length === 0" :og-content="ogContent" class="mt-3"/>
    <post-video class="mt-3" v-if="post.videos.length > 0" :src="post.videos[0]"></post-video>
    <post class="mt-3" :shared="true" :post="post.sharedPost" v-if="post.sharedPost"></post>

  </div>
</template>

<script>
import PostText from '@/components/post/PostText'
import PostImages from '@/components/post/PostImages'
import PostLinkPreview from '@/components/post/PostLinkPreview'
import PostVideo from '@/components/post/PostVideo'

export default {
  name: 'Post',
  components: {
    PostText,
    PostImages,
    PostLinkPreview,
    PostVideo
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    shared: {
      type: [Boolean, null],
      default: false
    },
    isImage: {
      default: null
    }
  },
  data() {
    return {
      showComments: false,
      showReactionsModal: false,
      postReactions: [],
      editMode: false,
      editedContent: this.post.content
    }
  },
  computed: {
    ogContent() {
      try {
        return this.post.ogContent ? JSON.parse(this.post.ogContent) : null
      } catch (e) {
        return null;
      }
    }
  },
  methods: {
    async getPostReactions() {
      try {
        const {data} = await this.$http.posts.getPostReactions(this.post.uid);
        this.postReactions = data;
      } catch (e) {

      }
    },
    showPostReactions() {
      this.getPostReactions();
      this.showReactionsModal = true;
    },
    async editPost() {
      try {
        if (!this.editedContent) {
          this.$notify.error('Content is required');
          return;
        }

        await this.$http.posts.updatePost(this.post.uid, this.editedContent);
        this.editMode = false;
        this.post.content = this.editedContent;
      } catch (e) {
        this.$notify.error('Something went wrong')
      }
    },
    cancelEdit() {
      this.editMode = false;
      this.editedContent = this.post.content;
    },
    openDeleteDialog() {
      this.$confirm('Are you sure want to delete post?', '', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          this.deletePost();
        })
    },
    async deletePost() {
      try {
        await this.$http.posts.deletePost(this.post.uid);
        this.$emit('postDeleted', this.post.uid)
      } catch (e) {
        this.$notify.error('Something went wrong!');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.post {
  padding: 22px 30px;
  background: #ffffff;
  position: relative;

  &--shared {
    background: var(--color-gray-lightest);
    border: 1px solid var(--color-gray-background);
    box-shadow: none !important;
    padding: 12px 20px;
  }

  .post-more {
    position: absolute;
    top: 25px;
    right: 32px;

    @media (max-width: 600px) {
      top: -1px;
      right: 11px;
    }
  }

  @media (max-width: 600px) {
    padding: 15px 15px;
  }
}
</style>
